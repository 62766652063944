import React from 'react';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import './BitaMultiSelect.scss';
import { Icons } from '../../constants/icons';

const BitaMultiSelect = props => {
  const { options, returnArray, containerStyle, listNames } = props;
  // Declaramos un array para guardar las opciones seleccionadas
  const [selected1, setSelected1] = React.useState(
    props.defaultSelected ? props.defaultSelected : [],
  );
  // Declara el objeto que se enviara con las opciones selecionas en true
  const objResult = {};

  React.useEffect(() => {
    if (returnArray) {
      props.handleSelection(selected1);
    } else {
      props.handleSelection(objResult);
    }
    // eslint-disable-next-line
  }, [selected1]);

  const Imagen = props.image;

  const selectMultipleOption = value => {
    // Se recorre el array compararando con el array de selected1 inicial para saber cuales opciones han sido seleccionadas
    options.forEach(item => {
      objResult[item] = !!value.includes(item);
    });
    setSelected1(value);
  };

  return (
    <div style={{ ...containerStyle }}>
      <Imagen className="buttonMultiSelect" style={{ ...props.imageStyle }} />
      <Picky
        value={selected1}
        options={options}
        className="multiselect"
        multiple
        onChange={selectMultipleOption}
        render={({ style, isSelected, item, selectValue, labelKey, valueKey, multiple }) => {
          return (
            <li style={style} className={isSelected ? 'selected' : ''} key={item[valueKey]}>
              <button
                type="button"
                onClick={() => selectValue(item)}
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  readOnly
                  style={{ opacity: 1, width: '10px', height: '10px' }}
                />
                <span>{listNames?.[item] ?? item}</span>
              </button>
            </li>
          );
        }}
      />
    </div>
  );
};

BitaMultiSelect.propTypes = {
  options: PropTypes.node.isRequired,
  handleSelection: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any,
};

BitaMultiSelect.defaultProps = {
  image: Icons.structured,
};

export default BitaMultiSelect;
